import React from "react"
import NavBar from "./nav-bar"
const Layout = ({ children }) => (
  <>
    <div className="h-20" />
    <NavBar />
    {children}
  </>
)
export default Layout
