import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/user/layout"
import Profile from "../components/user/profile"
import Login from "../components/user/login"
import PrivateRoute from "../components/user/privateRoute"

const User = () =>
  JSON.parse(process.env.GATSBY_ECOMMERCE) === true && (
    <Layout>
      <Router>
        <PrivateRoute path="/user/profile" component={Profile} />
        <Login path="/user/login" />
      </Router>
    </Layout>
  )
export default User
